import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import "../scss/pages/index.scss"

class About extends React.Component {
	componentDidMount(){
		window.jQuery(document).ready(function($){
			window.setTimeout(() => {
			var configProfile = {
				"profile": {"screenName": 'ghw_barristers'},
				"domId": 'twitterProfile',
				"maxTweets": 4,
				"enableLinks": true, 
				"showUser": true,
				"showTime": true,
				"showImages": false,
				"lang": 'en'
			};
			window.twitterFetcher.fetch(configProfile);
			window.setTimeout(() => {
				//window.jQuery('#twitterProfile ul').append('<li class="twitterProf bg-secondary text-white"></li>');
			},500);
			}, 500);
		});
	}
  render() {
    const { location, data } = this.props
    const s3i1 = get(data, 's3i1.childImageSharp.sizes')
    const s3i2 = get(data, 's3i2.childImageSharp.sizes')


    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="About" />
        <div>
		<section className="hero team" style={{backgroundImage: "url('/img/About-header.jpg')"}}>
				<div className="container py-5">
					<div className="row short-top-hero align-items-end">
						<div className="col-md-10">
							<h1 className="h1 pt-5 pl-3 pl-md-5">Individually, and as a collaborative team, we deliver strategic and thoughtful representation.</h1>							
						</div>
						<div className="col-md-2 text-right">			
							<Link className="mr-3" to="/contact/"><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></Link>
							<a target="_blank" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>	
						</div>	
					</div>
				</div>
			</section>
			<section className="bg-black fade-black-top py-3 py-lg-5">
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-white mt-md-5">
							<p >For four decades, we have represented both individual and corporate clients in difficult and complex cases at all levels of trial and appellate court. Our lawyers routinely appear before the Supreme Court of Canada, the Court of Appeal for Ontario, the Divisional Court, as well as superior and provincial trial courts across the province.</p>

							<p>GHW conducts trials and appeals across Canada. At the core of our practice is a focus on criminal law and related regulatory and administrative litigation.</p>

							<p>We are deeply committed to active membership in our community, through pro bono work and as contributors to the legal profession through mentorship, academic writing and teaching, and continuing legal education. </p>
						</div>
					</div>
				</div>
			</section>
			<section className="section-3 bg-black pb-5">
				<div className="container py-5">
					<div className="row">
						<div className="col-md-10 offset-md-1">												
							<div className="row s3-bg-2 mb-5" style={{backgroundImage:"url('/img/about-section3-image1.jpg')"}}>
								<div className="col-lg-6">
									<div className="row align-items-center">
										<div className="col-12 open-bracket-after mt-5">
											<div className="bg-primary text-white p-4">
											<p>Our counsel has been sought, and we have worked in collaboration with, colleagues across Canada and the United States as well as with firms in Europe and Asia. We provide skilled and insightful advocacy that has earned us a reputation for success and discretion.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row s3-bg-1 mt-5" style={{backgroundImage:"url('/img/about-section3-image2.jpg')"}}>						
								<div className="col-lg-7 offset-lg-5">
									<div className="row align-items-center">
										<div className="col-12 close-bracket-before my-5">
											<div className="bg-primary text-white p-4">
											<p>Led by partners Brian Greenspan, David Humphrey, Seth Weinstein, and Jill Makepeace, our experienced team handles a broad range of criminal and regulatory matters. </p>
											<p>Our sound advice and experience has been relied upon in domestic, international, and trans-border criminal and regulatory investigations and proceedings. </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-white twitter-section py-5">
				<div className="container">
					<div className="row">
						<div className="col-12 d-flex d-lg-block flex-column px-0">
							<div id="twitterProfile"></div>
							<div className="twitterProf bg-primary text-white">
								<img className="pt-3 pb-2" src="/img/twitter-white.png" alt=""/><br/>
								Greenspan<br/>
								Humphrey<br/>
								Weinstein LLP<br/>
								<br/>
								@GHW_barristers<br/>
								<a href="https://twitter.com/GHW_barristers" target="_blank" className="btn btn-outline-white mt-5">Follow us on Twitter</a>
							</div>
						</div>
						<div className="clearboth"></div>
					</div>
				</div>
			</section>
        </div>
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query AboutPageQuery {
    s3i1: file(name: { eq: "home-section3-image1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
	},
	s3i2: file(name: { eq: "home-section3-image2" }) {
		childImageSharp {
		  sizes(quality: 100) {
			...GatsbyImageSharpSizes_withWebp
		  }
		}
	  }
  }
`
